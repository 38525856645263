import React from "react"

import Index from "../components/index"
import Seo from "../components/other/seo"
import Container from "../components/layout/container";
import ButtonLink from "../components/elements/ButtonLink";

const NotFoundPage = () => (
  <Index>
    <Seo title="Page Not Found" />
    <Container>
      <h1 className="align-center text-blue"><br />Page Not Found</h1>
      <p className="align-center text-blue lead">The page you requested could not be found.</p>
      <p className="align-center text-blue">
        <ButtonLink href="/" text="Go back to home page" type="primary" />
      </p>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
    </Container>
  </Index>
)

export default NotFoundPage
